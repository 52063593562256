/***********************************************
* PhotomationCore.js
* 
* Handles the complete application view
***********************************************/
/* Layout files */
import '../assets/style/Photomation.css'

/* Global imports */
import React from 'react';

/* Local imports */
import API              from './API';
import OutputList       from './OutputList';
import { ProjectPopup } from './Popup';
import Project          from './Project';
import SideBar          from './SideBar';
import UploadPhotos     from './UploadPhotos';

class PhotomationCore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName: "",
            projectId: 0,
            prices: {base: 0.0, pc: 0.0, mesh: 0.0, autocad: 0.0, ortho: 0.0},
            epsg: [],
            sidebarOpen: false,
            editProject: false,
            uploadDone: false,
            imageCount: 0,
        };
        this.handlePrices = this.handlePrices.bind(this);
        this.handleEpsg   = this.handleEpsg.bind(this);
    }

    componentDidMount() {
        API.GetPrices(this.handlePrices);
        API.GetEpsg(this.handleEpsg);
    }

    handlePrices(reply) { this.setState({prices: reply[0]}); }
    handleEpsg(reply)   { this.setState({epsg: reply});      }

    render() {
        let ret = []
        let notTabletOrPhone = (!this.props.tablet && !this.props.phone);
        let tabletOrPhone = this.props.tablet || this.props.phone;
        let sbOpen = this.state.sidebarOpen || notTabletOrPhone;

        /* Title bar */
        if(this.state.projectName !== "") {
            let title = (
            <div className="projectname" key="projectname">
                <h1 key="title">{this.state.projectName}</h1>
                <button onClick={() => this.setState({editProject: true})}><span className="material-symbols-outlined">edit</span></button>
                <ProjectPopup 
                    title="Change Project Name" 
                    btnText="Save"
                    text={this.state.projectName}
                    open={this.state.editProject} 
                    onClose={() => this.setState({editProject: false})} 
                    onSave={(name) => {this.setState({editProject: false, projectName: name}); API.ChangeProjectName(this.state.projectId, name);}}
                />
            </div>);
            ret.splice(0, 0, title);
        }
        
        /* Project List */
        if(tabletOrPhone) { // If on a tablet or phone, show hamburger button
            ret.push(
            <div key="sidebar-hamburger" className="sbHamburger" onClick={() => this.setState({sidebarOpen: !this.state.sidebarOpen})}>
                <div className={(sbOpen) ? 'sbHambBar1 sbBarOpen' : 'sbHambBar1'}></div>
                <div className={(sbOpen) ? 'sbHambBar2 sbBarOpen' : 'sbHambBar2'}></div>
                <div className={(sbOpen) ? 'sbHambBar3 sbBarOpen' : 'sbHambBar3'}></div>
            </div>);
        }
        ret.push(<SideBar open={sbOpen} key="sidebar" onProjectChanged={(pId, pName) => this.setState({sidebarOpen: false, projectId: pId, projectName: pName})}/>);

        /* Open Project View */
        ret.push(                
        <div key="projectView" className={(sbOpen && notTabletOrPhone) ? 'projectView sbOpen' : 'projectView'}>
            <Project projectId={this.state.projectId} prices={this.state.prices} epsg={this.state.epsg} tablet={this.props.tablet} phone={this.props.phone} uploadDone={this.state.uploadDone} imageCount={this.state.imageCount}/>
        </div>);

        /* Output List */
        ret.push(
        <div key="outputUpload" className="outputUpload">
            <div key="outputlist" className="outputListView"><OutputList id={this.state.projectId}/></div>
            <div key="upload" className="uploadView"><UploadPhotos project={this.state.projectId} uploadDone={(state) => {this.setState({uploadDone: state})}} imageCount={(count) => {this.setState({imageCount: count})}}/></div>
        </div>);

        return ret;
    }
}

export default PhotomationCore;