/***********************************************
* Overview.js
* 
* Creates an overview page of users and 
* projects for admins
***********************************************/
/* Layout files */
import '../assets/style/Overview.css'

/* Global imports */
import React, { useEffect, useState } from 'react';
import { useNavigate }                from "react-router-dom";

/* Local imports */
import API from './API';

export default function Overview() {
    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const [projectList, setProjectList] = useState([]);

    const usersDone = reply => {
        if(reply.length !== 0) { setUserList(reply);    }
        else  { navigate('/'); }
    }

    const projectsDone = reply => {
        if(reply.length !== 0) { setProjectList(reply); }
    }

    useEffect(()=>{ 
        API.GetUsers(usersDone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
    <div className="overviewView">
        <div className="overviewPage">
            <table><tbody>
                <tr><td colSpan={2}><h1><span className="material-symbols-outlined">person</span><span className="text">Users</span></h1></td></tr>
                {userList.map((user, i) => {
                    return (<>
                        <tr className='dataRow' onClick={() => {API.GetUserProjects(user.id, projectsDone)}} key={"user" + i}><td>{user.id}</td><td><span>{user.username}</span></td></tr>
                        <tr key={"space" + i} className='spaceRow'></tr>
                    </>)})}
            </tbody></table>

            <table><tbody>
                <tr><td colSpan={2}><h1><span className="material-symbols-outlined">description</span><span className="text">Projects</span></h1></td></tr>
                {projectList.map((project, i) => {
                    return (<>
                        <tr className='dataRow' key={"project" + i}><td>{project.id}</td><td><span>{project.title}</span></td></tr>
                        <tr key={"space" + i} className='spaceRow'></tr>
                    </>)})}
            </tbody></table>
        </div>
    </div>
    );
}