/***********************************************
* NewOpenProject.js
* 
* Handles the new project button and popup
***********************************************/

/* Global imports */
import React from 'react';

/* Local imports */
import { ProjectPopup } from "./Popup"

export default class NewOpenProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = { createProject: false, };
    }

    render() {
        return (
        <div className="newOpenProject">
            <ProjectPopup 
                title="New Project" 
                btnText="Create" 
                text="" 
                open={this.state.createProject} 
                onClose={() => this.setState({createProject: false})} 
                onSave={(name) => {
                    this.setState({createProject: false}); 
                    this.props.onSave(name)
                }}
            />
            <button 
                onClick={() => { this.setState({createProject: true}) }} 
                className='hugeButton shadow'
            >
                New Project
            </button>
        </div>);
    }
}