/***********************************************
* Download.js
* 
* Handles the download request for the emails
***********************************************/

/* Global imports */
import React, { useEffect, useState } from 'react';
import { useSearchParams }            from "react-router-dom";

/* Local imports */
import API               from './API';
import { ProgressPopup } from './Popup';

export default function Download() {
    const [searchParams] = useSearchParams();
    const [downloaded, setDownloaded] = useState(false);
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(0.0);

    useEffect(() => { setOpen(progress > 0.0 && progress < 1.0); }, [progress]);
    
    /* Get the search bar parameter specifying the files */
    var filename  = searchParams.get("file");
    var projectId = searchParams.get("project_id");
    var time      = searchParams.get("time");

    if((downloaded === false) && (filename !== null && projectId !== null && time !== null)) {
        API.DownloadOutputFile(projectId, time, filename, setProgress, null);
        setDownloaded(true);
    }

    return (
    <div className="downloadPage">
        <ProgressPopup open={open} progress={Math.round(progress * 100)} title={filename} />
    </div>
    );
}