/***********************************************
* UserInfo.js
* 
* Handles the user info view and 
* the user create view
***********************************************/
/* Layout files */
import '../assets/style/UserInfo.css'

/* Global imports */
import React, { useState, useRef }  from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ReCAPTCHA                               from 'react-google-recaptcha';
import Markdown                                from 'markdown-to-jsx'
import { CountryDropdown }                     from 'react-country-region-selector';


/* Local imports */
import API        from './API';
import { config } from "./Constants"
import Storage    from './Storage';

import disclaimerMarkdown from '../docs/Disclaimer.md';


/* Allow this component to navigate to different pages */
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (<Component {...props} router={{ location, navigate, params }} />);
    }
  
    return ComponentWithRouterProp;
}

/* A text input which can be switch between editable and not editable */
const EditableText = (props) => {
    const [textValue, setTextValue] = useState(props.value);
    const [propText, setPropText] = useState(props.value);
    const [checkEmpty, setCheckEmpty] = useState(props.checkEmpty);

    if(props.value !== propText) {  
        setPropText(props.value); 
        setTextValue(props.value); 
    }

    if(props.checkEmpty !== checkEmpty) {
        setCheckEmpty(props.checkEmpty);
    }

    const handleChange = event => {
        setTextValue(event.target.value);
        if(props.onChange !== undefined)  props.onChange(event.target.value);
    }

    const handleFocusOut = event => {
        if(props.onFocusOut !== undefined)  props.onFocusOut(event.target.value);
    }

    const handleKeyUp = event => {
        if (event.key === 'Enter') {
            if(props.onEnter !== undefined)  props.onEnter(event.target.value);
        }
    }

    if(props.editable === false) {
        return (<span className={props.className + " notEditable"}>{textValue}</span>);
    } else {
        return (<input type="text" value={textValue} onInput={handleChange} onBlur={handleFocusOut} onKeyUp={handleKeyUp} className={props.className + (checkEmpty && (textValue !== undefined && textValue.trim() === "") ? " emptyInput" : "")}/>);
    }
};

/* Create a form from an object */
const FormData = (props) => {
    const [propValues, setPropValues] = useState(props.values);
    const [values, setValues] = useState(props.values);
    const [checkEmpty, setCheckEmpty] = useState(false);

    const handleSave = () => {
        setCheckEmpty(true);
        var vals = {};
        var empty = false;
        for(const key in values){ 
            if(values[key].value === "")
                empty = true
            vals[key] = values[key].value;
        }
        if(!empty) {
            props.onSave(vals);
        }
    }

    if(props.values !== propValues) {
        setPropValues(props.values);
        setValues(props.values);
    }

    if(values === null) {
        return (<></>);
    } else {
        var ret = [];
        for(const key in values) {
            if(values[key] !== undefined) {
                var capitalKey = key.replace("_", " ").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                ret.push(<tr className='dataRow' key={key}>
                    <td>{capitalKey}</td>
                    <td colSpan={(values[key].size === 1) ? 2 : 1}>
                        {(values[key].hasOwnProperty("type") && values[key].type === "country") ?
                        <CountryDropdown
                            className="editableInput" 
                            defaultOptionLabel={propValues[key]["value"]}
                            value={values[key]["value"]}
                            onChange={(event) => setValues({...values, [key]: {...values[key], value: event}})} 
                        />
                        :
                        <EditableText 
                            className="editableInput" 
                            editable={values[key].editable} 
                            value={values[key]["value"]} 
                            onChange={(event) => setValues({...values, [key]: {...values[key], value: event}})}
                            onFocusOut={(event) => {
                                if(values[key].hasOwnProperty("check") && values[key].check === true) {
                                    if(props.onCheck !== undefined)  props.onCheck(event);
                                }
                            }}
                            onEnter={(value) => {
                                if(value !== "") {
                                    handleSave();
                                }
                            }}
                            checkEmpty={checkEmpty}
                        />
                        }
                    </td>
                    {(values[key].size === 1) ? <></> : <td className='emptyColumn'></td>}
                </tr>);
                ret.push(<tr key={key + "space"} className='spaceRow'></tr>)
            }
        }
        if(props.msg !== undefined || props.msg !== "") {
            ret.push(<tr key='msg'><td colSpan={3}>{props.msg}</td></tr>);
        }

        ret.push(<tr key="save"><td>
            <button onClick={() => {  handleSave();  }} className='bigButton'>Save</button> 
        </td><td className='emptyColumn'></td><td className='emptyColumn'></td></tr>)
    
        return (<table className={props.className}><tbody>
            <tr><td colSpan={3}><h1>
                <span className="material-symbols-outlined">{props.icon}</span>
                <span className="text">{props.title}</span>
            </h1></td></tr>
            {ret}
        </tbody></table>);
    }
};

/* A password input */
const PasswordField = (props) => {
    const handleKeyUp = event => {
        if (event.key === 'Enter') {
            if(props.onEnter !== undefined)  props.onEnter(event.target.value);
        }
    }

    return (<tr className='dataRow'>
        <td>{props.title}</td>
        <td colSpan={2}><input type="password" className="editableInput" value={props.password} onInput={props.onInput} onKeyUp={handleKeyUp} /></td>
        <td className='spaceColumn'></td>
        <td className={((props.error !== "") ? 'errorMsg' : '') + " emptyColumn"}><span>{props.error}</span></td>
    </tr>);
}

/* Change password form with a captcha */
const ChangePassword = (props) => {
    const [oldPw,       setOldPw]       = useState("");
    const [newPw1,      setNewPw1]      = useState("");
    const [newPw2,      setNewPw2]      = useState("");
    const [pwError,     setPwError]     = useState("");
    const [oldPwError,  setOldPwError]  = useState("");
    const [newPw1Error, setNewPw1Error] = useState("");
    const [newPw2Error, setNewPw2Error] = useState("");

    const captcha = useRef(null);

    if(props.pwError !== pwError) {
        setPwError(props.pwError);
        setOldPwError(props.pwError);
        if(props.pwError === "") {
            setOldPw("");
            setNewPw1("");
            setNewPw2("");
        }
    }

    const checkPw = () => {
        setOldPwError("");
        setNewPw1Error("");
        setNewPw2Error("");
        if(!props.newPw && oldPw === "") {
            setOldPwError("Password can't be empty");
        } else if(newPw1 === "") {
            setNewPw1Error("Password can't be empty");
        } else if(newPw1 !== newPw2) {
            setNewPw2Error("Passwords don't match");
        } else {
            if(props.captcha === true) {
                var captchaToken = captcha.current.getValue();
                captcha.current.reset();
                if(captchaToken !== "") {
                    props.onSave(oldPw, newPw1, captchaToken);
                }
            } else {
                props.onSave(oldPw, newPw1);
            }
        }
    }

    var newPw = props.newPw;
    return (
    <table className={props.className}><tbody>
        <tr><td colSpan={4}><h1><span className="material-symbols-outlined">password</span><span className="text">{(newPw) ? "Password" : "Change Password"}</span></h1></td></tr>
        {(newPw) ? <></> : 
        <>
            <PasswordField title="Old Password" password={oldPw} onInput={(e) => setOldPw(e.target.value)} onEnter={checkPw} error={oldPwError} />
            <tr><td colSpan={2}><hr /></td></tr>
        </>}
        <PasswordField title={(newPw) ? "Password" : "New Password"} password={newPw1} onInput={(e) => setNewPw1(e.target.value)} onEnter={checkPw} error={newPw1Error} />
        <tr className='spaceRow'></tr>
        <PasswordField title="Confirm Password" password={newPw2} onInput={(e) => setNewPw2(e.target.value)} onEnter={checkPw} error={newPw2Error} />
        {(props.captcha === true)
            ? <><tr className='spaceRow'></tr><tr><td colSpan={4}><ReCAPTCHA sitekey={config.CAPTCHA_KEY} ref={captcha}/></td></tr></>
            : <></>
        }
        <tr><td colSpan={4}><button onClick={checkPw} className='bigButton'>{(newPw) ? "Save Password" : "Change Password"}</button></td></tr>
    </tbody></table>
    );
};

/* Show user info page */
export class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: null,
            invoiceData: null,
            pwError: "",
        };

        this.handleUserInfo = this.handleUserInfo.bind(this);
        this.handleChangeDone = this.handleChangeDone.bind(this);
    }

    /* When shown, load all user info */
    componentDidMount() { API.GetUserInfo(this.handleUserInfo); }

    /* Parse reply with user info */
    handleUserInfo(reply) {
        this.setState({
        invoiceData: {
            company:  {value: reply.company,  size: 1, editable: true},
            vat:      {value: reply.vat,      size: 1, editable: true},
            street:   {value: reply.street,   size: 1, editable: true},
            number:   {value: reply.number,   size: 0, editable: true},
            postcode: {value: reply.postcode, size: 0, editable: true},
            city:     {value: reply.city,     size: 1, editable: true},
            country:  {value: reply.country,  size: 1, editable: true},
        },
        userInfo: {
            username:   {value: reply.user.username,   size: 1, editable: false},
            email:      {value: reply.user.email,      size: 1, editable: true},
            first_name: {value: reply.user.first_name, size: 1, editable: true},
            last_name:  {value: reply.user.last_name,  size: 1, editable: true},
        }});
    }

    handleChangeDone(reply) { this.setState({pwError: ("Error" in reply) ? reply.Error : ""}); }

    render() {
        let ret = []

        /* User View */
        ret.push(
            <div className="userView" key="userView">
                <div className="userPage">
                    <FormData icon="person" title="User" values={this.state.userInfo} onSave={(data) => API.ChangeUser(data)}/>
                    <ChangePassword newPw={false} onSave={(o, n) => API.ChangePassword(o, n, this.handleChangeDone)} pwError={this.state.pwError}/>
                    <FormData icon="receipt_long" title="Invoice Info" values={this.state.invoiceData} onSave={(data) => API.ChangeInvoiceInfo(data)}/>
                </div>
            </div>
        );

        return ret;
    }
}

/* Show user create page */
class UserCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createState: 0,
            invoiceData: {
                vat:      {value: "", size: 1, editable: true, check: true},
                company:  {value: "", size: 1, editable: true},
                street:   {value: "", size: 1, editable: true},
                number:   {value: "", size: 0, editable: true},
                postcode: {value: "", size: 0, editable: true},
                city:     {value: "", size: 1, editable: true},
                country:  {value: "Belgium", size: 1, editable: true, type: "country"},
            },
            userInfo: {
                username:   {value: "", size: 1, editable: true},
                email:      {value: "", size: 1, editable: true},
                first_name: {value: "", size: 1, editable: true},
                last_name:  {value: "", size: 1, editable: true},
            },
            password: "",
            userError: "",
            pwError: "",
            markdown: "",
        };

        fetch(disclaimerMarkdown).then(res => res.text()).then(text => this.setState({ markdown: text }));

        this.handleCheckDone  = this.handleCheckDone.bind(this);
        this.handleCreateDone = this.handleCreateDone.bind(this);
        this.handleCheck      = this.handleCheck.bind(this);
        this.handleAccept     = this.handleAccept.bind(this);
    }

    handleCheckDone(reply)  { this.setState((reply["Code"] === 0) ? {createState: 2} : {userError: reply["Error"]}); }
    handleCreateDone(reply) {
        API.GetToken(this.state.userInfo.username.value, this.state.password, null, null);
        this.setState({createState: 3, password: ""});
    }

    handleCheck(response) {
        response.text().then(text => {
            var data = JSON.parse(text); 
            var invData = this.state.invoiceData;
            if(data.valid === true) {
                if(invData.company.value === "")  invData.company.value = data.name;
                if(invData.street.value === "")   invData.street.value = data.address.street;
                if(invData.number.value === "")   invData.number.value = data.address.number;
                if(invData.postcode.value === "") invData.postcode.value = data.address.zip_code;
                if(invData.city.value === "")     invData.city.value = data.address.city;
                if(invData.country.value === "")  invData.country.value = data.address.country;
            }
            this.setState({invoiceData: invData});
        });
    }

    handleAccept() {
        this.setState({createState: 1});
    }

    render() {
        let ret = []
        const navigate = this.props.router.navigate;
        const { markdown } = this.state;

        /* User Create */ 
        ret.push(<div key="disclaimer" >
            <div className={(this.state.createState > 0) ? "leftForm slideForm disclaimer" :"visibleForm slideForm disclaimer"}>
                <Markdown>{markdown}</Markdown>
                <button onClick={this.handleAccept} className='bigButton'>Accept</button>
            </div>
        </div>);
        ret.push(<div key="userInfo" >
            <FormData 
                icon="person" 
                title="User" 
                values={this.state.userInfo} 
                msg={this.state.userError}
                onSave={(data) => {
                    var tmp = this.state.userInfo;
                    for(const key in data) { tmp[key].value = data[key]; }
                    this.setState({userInfo: tmp});
                    Storage.RemoveToken();
                    API.CheckUser(data, this.handleCheckDone);
                }}
                className={(this.state.createState < 1) ? "rightForm slideForm" : ((this.state.createState > 1) ? "leftForm slideForm" :"visibleForm slideForm")}
            /></div>);
        ret.push(<div key="password">
            <ChangePassword 
                newPw={true} 
                captcha={true} 
                onSave={(o, n, captcha) => {
                    this.setState({createState: 3}); 
                    var data = {};
                    var tmp = this.state.userInfo;
                    for(const key in tmp){ data[key] = tmp[key].value; }
                    data["password"] = n;
                    data["captcha_token"] = captcha;
                    API.CreateUser(data, this.handleCreateDone);
                    this.setState({password: n});
                }} 
                pwError={this.state.pwError}
                className={(this.state.createState < 2) ? "rightForm slideForm" : ((this.state.createState > 2) ? "leftForm slideForm" :"visibleForm slideForm")}
            /></div>);
        ret.push(<div key="invoiceInfo">
            <FormData 
                icon="receipt_long" 
                title="Invoice Info" 
                values={this.state.invoiceData} 
                onSave={(data) => {
                    this.setState({createState: 0}); 
                    API.ChangeInvoiceInfo(data);
                    navigate("/projects");
                }}
                onCheck={(data) => {
                    fetch("https://controleerbtwnummer.eu/api/validate/" + data + ".json", {method: "GET", headers: {}}).then(this.handleCheck);
                }}
                className={(this.state.createState < 3) ? "rightForm slideForm" : ((this.state.createState > 3) ? "leftForm slideForm" :"visibleForm slideForm")}
            /></div>);

        return (<div className="userView userCreateView" key="userView"><div className="userPage">{ret}</div></div>);
    }
}

export default withRouter(UserCreate);