/***********************************************
* OutputList.js
* 
* Show the output files of a project
***********************************************/
/* Layout files */
import '../assets/style/OutputList.css';

/* Global imports */
import React, { useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';

/* Local imports */
import { ProgressPopup } from './Popup';
import API from './API';

/* Combine all files with the same output type (2D, 3D, ...) */
function OutputType(id, item, type, downloadProgress) {
    var ret = []
    var items = item.Types[type]
    if(items.length > 0) {
        items.forEach((itemtype, j) => { 
            ret.push(
            <tr key={itemtype.type}>
                {/* Show the type name in the first column */}
                { (j === 0) ? <th rowSpan={items.length} className={"header" + type}>{type}</th> : <></> }
                <td>{itemtype.type}</td>
                <td><button className="smallButton" onClick={() => { API.DownloadOutputFile(id, item.Date, itemtype.filename, (p)=>{downloadProgress(p, itemtype.filename)}, null) }}>Download</button></td>
            </tr>) });
    }
    return ret;
}

export default function OutputList(props) {
    const [list, setList] = useState([]);
    const [id,   setId]   = useState(props.id);
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(0.0);
    const [downloadFile, setDownloadFile] = useState("");

    /* Convert the server date notation to a javascript Date object */
    const toDate = (item) => {
        var dt = item.split("_");
        return new Date(dt[0] + "T" + dt[1].replaceAll("-", ":"));
    }

    const handleOutputFileList = (outputList) => {
        /* Sort the list according to date */
        outputList.sort((a, b) => {
            var timeA = toDate(a.Date);
            var timeB = toDate(b.Date);
            if(timeA < timeB)  return 1;
            if(timeA > timeB)  return -1;
            return 0;
        });
        setList(outputList);
    }

    /* Poll the output files every 10 seconds */
    useEffect(() => {
        const interval = setInterval(() => {
            setId(newId => { 
                API.GetOutputFiles(newId, handleOutputFileList);
                return newId;
            });
        }, 10000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* If the project id changes, reload the output files */
    if(props.id !== id) {
        setId(props.id);
        API.GetOutputFiles(props.id, handleOutputFileList);
    }

    /* Build the list of output file dates */
    var ret = [];
    var colList = [];
    list.forEach((item, i) => {
        var date = toDate(item.Date)
        colList.push(
        <Collapsible  key={"output" + i} trigger={date.toLocaleString()} open={(i === 0)}>
            <table>
                <tbody>
                    { OutputType(id, item, "2D", (progress, name)=>{
                        setOpen(progress > 0.0 && progress < 1.0);
                        setDownloadFile(name); 
                        setProgress(progress);}) }
                    { OutputType(id, item, "3D", (progress, name)=>{
                        setOpen(progress > 0.0 && progress < 1.0);
                        setDownloadFile(name); 
                        setProgress(progress);}) }
                </tbody>
            </table>
        </Collapsible>);
    });

    /* Show the title with icon */
    ret.push(
        <h1 key="header">
            <span className="material-symbols-outlined">description</span>
            <span className="text">Output Files</span>
        </h1>);
    /* Show the list with output files */
    ret.push(<div key="list" className="outputList">{colList}</div>);
    /* Show the download progress when a file is downloaded */
    ret.push(<ProgressPopup key="popup" open={open} progress={Math.round(progress * 100)} title={downloadFile} />);

    return ret;
}