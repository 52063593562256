/***********************************************
* LandingPage.js
* 
* Show a landingpage
***********************************************/
/* Layout files */
import '../assets/style/LandingPage.css';

/* Global imports */
import React   from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Markdown from 'markdown-to-jsx'

import landingMarkdown from '../docs/LandingPageNL.md';

/* Allow this component to navigate to different pages */
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (<Component {...props} router={{ location, navigate, params }} />);
    }
  
    return ComponentWithRouterProp;
}

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { markdown: '' };

        fetch(landingMarkdown).then(res => res.text()).then(text => this.setState({ markdown: text }));
    }

    render() {
        const navigate = this.props.router.navigate;
        const { markdown } = this.state;

        return (
            <div className="fullLandingPage">
                <div className="loginLandingDiv">
                    <div onClick={() => navigate("/projects")} className="button bigButton">Login</div>
                </div>
                <div className='contentPage'>
                    <Markdown>{markdown}</Markdown>
                </div>
            </div>
        );
    }
}

export default withRouter(LandingPage);