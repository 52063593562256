/***********************************************
* API.js
* 
* Handles the API calls to the backend
***********************************************/
/* Global imports */
import axios from 'axios';
import download from 'downloadjs';

/* Local imports */
import { config } from "./Constants"
import Storage from './Storage';

export default class API {
    /* Create the body of a http form from an object */
    static CreateFormBody(obj) {
        var formBody = [];
        Object.keys(obj).forEach(key => { formBody.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])); });
        return formBody.join("&");
    }

    /* Get a user token, by sending a username and password */
    static GetToken(username, password, SuccessCallback, FailureCallback) {
        var formBody = API.CreateFormBody({username: username, password: password});
        const requestOptions = { method: 'POST', headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, body: formBody };
    
        fetch(config.API_URL + `token`, requestOptions)
        .then(API.handleResponse)
        .then((jsonReply) => { 
            Storage.SetToken(username, jsonReply.token);    // Save the token in local storage
            if(SuccessCallback != null)  SuccessCallback(jsonReply);
        }).catch((e) => {
            if(FailureCallback != null)  FailureCallback();
        });
    }

    /* Get a list of projects (id: -1) or the info of a specific project (id > 0)*/
    static GetProject(projectId, replyCallback) {
        API.DoFetch((projectId <= 0) ? `photomation/` : `photomation/` + projectId + `/`, 'GET')
        .then((jsonReply) => { 
            if(replyCallback != null)   replyCallback(jsonReply);
        });
    }

    /* Create a new project with a name */
    static CreateProject(name, replyCallback) {
        /* Add some default values to the project */
        var formBody = API.CreateFormBody({title: name, generatePointcloud: true, generateMesh: true, generateAutocad: true, generateOrtho: true, epsg: "31370"});
        API.DoFetch(`photomation/`, 'POST', formBody)
        .then(jsonReply => { 
            if(replyCallback != null)   replyCallback(jsonReply);
        });
    }

    /* Delete an existing project with id */
    static RemoveProject(projectId, replyCallback) {
        API.DoFetch(`photomation/` + projectId + `/`, 'DELETE')
        .then((jsonReply) => { 
            if(replyCallback != null)   replyCallback(jsonReply);
        });
    }

    /* Get a list of images of a project */
    static GetImages(projectId, replyCallback) {
        if(projectId > 0) {
            API.DoFetch(`project/` + projectId + `/images/`, 'GET')
            .then((jsonReply) => { 
                if(replyCallback != null)   replyCallback(jsonReply);
            });
        }
    }

    /* Change the boundingbox of a project */
    static ChangeBoundingBox(projectId, bb) {
        var formBody = API.CreateFormBody({project_id: projectId, autoBoundingBox: false, centerLongitude: bb.long, centerLatitude: bb.lat, roll: bb.rotation, width: bb.width, height: bb.height, depth: bb.depth});
        API.DoFetch(`boundingbox/` + bb.id + `/`, 'PUT', formBody);
    }

    /* Change some settings of a project */
    static ChangeProject(projectId, generate, pcPoints, meshTriangles) {
        var formBody = API.CreateFormBody({generate_pc: generate.pc, generate_obj: generate.obj, generate_ad_mesh: generate.ad_mesh, generate_ad_recap: generate.ad_recap, generate_ortho: generate.ortho, pointcloudPoints: pcPoints, meshTriangles: meshTriangles});
        API.DoFetch(`photomation/` + projectId + `/`, 'PATCH', formBody);
    }

    /* Save the map Zoom level of a project */
    static ChangeZoom(projectId, zoomLevel) {
        var formBody = API.CreateFormBody({zoomLevel: zoomLevel});
        API.DoFetch(`photomation/` + projectId + `/`, 'PATCH', formBody);
    }

    /* Change the project name */
    static ChangeProjectName(projectId, projectName) {
        var formBody = API.CreateFormBody({title: projectName});
        API.DoFetch(`photomation/` + projectId + `/`, 'PATCH', formBody);
    }

    /* Change the marker type */
    static ChangeDistsCoords(projectId, value) {
        var formBody = API.CreateFormBody({distsOrCoords: value});
        API.DoFetch(`photomation/` + projectId + `/`, 'PATCH', formBody);
    }

    /* Change the marker type */
    static ChangeMarkerType(projectId, value) {
        var formBody = API.CreateFormBody({markerType: value});
        API.DoFetch(`photomation/` + projectId + `/`, 'PATCH', formBody);
    }

    /* Add a distance marker */
    static AddDistance(projectId, distance, doneCallback){
        var formBody = API.CreateFormBody({project_id: projectId, point1: distance.point1, point2: distance.point2, distance: distance.distance});
        API.DoFetch(`distance/`, 'POST', formBody).then((jsonReply) => { if(doneCallback != null)   doneCallback(jsonReply); });
    }
    /* Change a distance marker */
    static ChangeDistance(distance){
        var formBody = API.CreateFormBody({point1: distance.point1, point2: distance.point2, distance: distance.distance});
        API.DoFetch(`distance/` + distance.id + `/`, 'PUT', formBody);
    }
    /* Delete a distance marker */
    static DeleteDistance(id){ API.DoFetch(`distance/` + id + `/`, 'DELETE'); }

    /* Add a coordinate marker */
    static AddCoordinate(projectId, coordinate, doneCallback){
        var formBody = API.CreateFormBody({project_id: projectId, point: coordinate.point, lat: coordinate.lat, lng: coordinate.lng, alt: coordinate.alt});
        API.DoFetch(`coordinate/`, 'POST', formBody).then((jsonReply) => { if(doneCallback != null)   doneCallback(jsonReply); });
    }
    /* Change a coordinate marker */
    static ChangeCoordinate(coordinate){
        var formBody = API.CreateFormBody({point: coordinate.point, lat: coordinate.lat, lng: coordinate.lng, alt: coordinate.alt});
        API.DoFetch(`coordinate/` + coordinate.id + `/`, 'PUT', formBody);
    }
    /* Delete a coordinate marker */
    static DeleteCoordinate(id){ API.DoFetch(`coordinate/` + id + `/`, 'DELETE'); }

    /* Change the marker triangle */
    static ChangeTriangle(id, triangle) {
        var formBody = API.CreateFormBody(triangle);
        API.DoFetch(`triangle/` + triangle.id + `/`, 'PATCH', formBody);
    }

    /* Get the epsg options */
    static GetEpsg(replyCallback) {
        API.DoFetch(`epsg/`, 'GET')
        .then((jsonReply) => { 
            if(replyCallback != null)   replyCallback(jsonReply);
        });
    }

    /* Upload a list of files, one by one */
    static UploadFiles(projectId, files, progressFiles, doneFiles, doneCallback, progressCallback) {
        API.UploadFile(projectId, 0, files, progressFiles, doneFiles, files.length, 0, progressCallback, doneCallback);
    }

    /* Upload a file */
    static UploadFile(id, imgId, todoList, progressList, doneList, fileCount, progress, progressCb, doneCb) {
        var file = todoList.shift();
        var reply = null;
        var token = null;
        if(file !== undefined) {
            progressList.push(file);
            const data = new FormData();
            data.append(`file-0`, file, file.name);
            data.append("project_id", id);
            data.append("id", imgId);
            reply = Storage.GetToken()
            if(reply != null) {
                token = reply["userToken"];
                axios.request({
                    method: "post", 
                    url: config.API_URL + 'uploadimg/',
                    headers: { 'Authorization': "Token " + token },
                    data: data, 
                    onUploadProgress: (p) => {
                        if(progressCb != null)    progressCb(progress + (p.progress / fileCount), p.progress);
                    }
                })
                .then(API.handleAxiosResponse)
                .then(() => {
                    progressList.shift();
                    doneList.push(file);
                    imgId++;
                    API.UploadFile(id, imgId, todoList, progressList, doneList, fileCount, progress + (1 / fileCount), progressCb, doneCb);
                });
            }
        } 
        
        if((file === undefined && fileCount < 10) || (imgId === 9)) {
            const data = new FormData();
            data.append("project_id", id);
            data.append("upload_done", true);
            reply = Storage.GetToken()
            if(reply != null) {
                token = reply["userToken"];
                axios.request({
                    method: "post", 
                    url: config.API_URL + 'uploadimg/',
                    headers: { 'Authorization': "Token " + token },
                    data: data,
                })
                .then(API.handleAxiosResponse)
                .then(() => {
                    if(fileCount < 10) {
                        if(progressCb != null) progressCb(1.0);
                        if(doneCb != null)     doneCb();
                    }
                });
            }
        }

        if(file === undefined && fileCount >= 10) {
            if(progressCb != null) progressCb(1.0);
            if(doneCb != null)     doneCb();
        }
    }

    /* Add a project to the run list */
    static AddProjectToRunList(projectId) {
        var formBody = API.CreateFormBody({project_id: projectId});
        API.DoFetch(`runlist/`, 'POST', formBody);
    }

    /* Get the output files of a project */
    static GetOutputFiles(projectId, doneCallback) {
        if(projectId > 0) {
            var formBody = API.CreateFormBody({project_id: projectId});
            API.DoFetch(`outputlist/`, 'POST', formBody)
            .then((jsonReply) => {
                if(doneCallback != null)    doneCallback(jsonReply);
            });
        } else { /* If invalid project ID, return an empty list */
            if(doneCallback != null)    doneCallback([]);
        }
    }

    /* Get the prices for this user */
    static GetPrices(doneCallback) {
        API.DoFetch(`price/`, 'GET')
        .then((jsonReply) => {
            if(doneCallback != null)    doneCallback(jsonReply);
        });
    }
    
    /* Get the information of the logged in user */
    static GetUserInfo(doneCallback) {
        API.DoFetch(`userinfo/`, 'GET')
        .then((jsonReply) => {
            if(doneCallback != null)    doneCallback(jsonReply);
        });
    }

    /* Create a new user */
    static CreateUser(data, doneCallback) {
        var formBody = API.CreateFormBody(data);
        API.DoFetchNoToken(`createuser/`, 'POST', formBody)
        .then(jsonReply => { 
            if(doneCallback != null)   doneCallback(jsonReply);
        });
    }

    /* Check if username is valid and not in use */
    static CheckUser(data, doneCallback) {
        var formBody = API.CreateFormBody(data);
        API.DoFetchNoToken(`checkuser/`, 'POST', formBody)
        .then(jsonReply => { 
            if(doneCallback != null)   doneCallback(jsonReply);
        });
    }

    /* change the password of the logged in user */
    static ChangePassword(oldPw, newPw, doneCallback) {
        var formBody = API.CreateFormBody({oldPw: oldPw, newPw: newPw});
        API.DoFetch(`changepassword/`, 'PATCH', formBody)
        .then((jsonReply) => {
            if(doneCallback != null)    doneCallback(jsonReply);
        });
    }
    
    /* Change information of the logged in user */
    static ChangeUser(data) {
        var formBody = API.CreateFormBody(data);
        API.DoFetch(`changeuser/`, 'PATCH', formBody);
    }

    /* Change the invoice info of the logged in user */
    static ChangeInvoiceInfo(data) {
        var formBody = API.CreateFormBody(data);
        API.DoFetch(`userinfo/`, 'PATCH', formBody);
    }

    /* Get all users (only for Admin) */
    static GetUsers(doneCallback) {
        API.DoFetch(`users/`, 'GET')
        .then((jsonReply) => { if(doneCallback != null) doneCallback(jsonReply); })
        .catch((err) => {      if(doneCallback != null) doneCallback(err);       });
    }

    /* Get all projects of a user (only for Admin) */
    static GetUserProjects(id, doneCallback) {
        API.DoFetch(`user/` + id + `/projects/`, 'GET')
        .then((jsonReply) => { if(doneCallback != null) doneCallback(jsonReply); });
    }

    /* Download an output file */
    static DownloadOutputFile(projectId, time, filename, progressCallback, doneCallback) {
        var url = config.API_URL + `downloadoutput/`;
        var reply = Storage.GetToken()
        if(reply != null) {
            var token = reply["userToken"];
            var formBody = API.CreateFormBody({project_id: projectId, time: time, filename: filename});
            axios.request({
                method: "post", 
                url: url, 
                responseType: "arraybuffer",
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': "Token " + token },
                data: formBody, 
                onDownloadProgress: (p) => {
                    if(progressCallback != null)    progressCallback(p.progress);
                }
            })
            .then ((resp) => {
                download(resp.data, filename)
                if(doneCallback != null)    doneCallback();
            })
        }
    }

    /* Do a request and add authorization */
    static DoFetch(url, method, data) {
        var reqOptions = {method: method, headers: {}};
        var apiUrl = config.API_URL + url;
        var reply = Storage.GetToken()
        if(reply != null) {
            var token = reply["userToken"];
            reqOptions.headers['Authorization'] = "Token " + token;
            if(data !== undefined) {
                reqOptions.body = data
                reqOptions.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            }
            return fetch(apiUrl, reqOptions).then(API.handleResponse);
        }
        return Promise.reject("Not logged in");
    }

    /* Do a request without authorization */
    static DoFetchNoToken(url, method, data) {
        var reqOptions = {method: method, headers: {}};
        var apiUrl = config.API_URL + url;
        if(data !== undefined) {
            reqOptions.body = data
            reqOptions.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        return fetch(apiUrl, reqOptions).then(API.handleResponse);
    }

    /* Handle the response and convert it to a JSON object */
    static handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    Storage.RemoveToken();  // auto logout if 401 response returned from api
                }

                var error = (data && data.message) || response.statusText;
                if(response.status === 403) {
                    error = [];
                }
                return Promise.reject(error);
            }
    
            return data;
        });
    }

    static handleAxiosResponse(response) {
        const data = response.data;
        if (response.statusText !== "OK") {
            if (response.status === 401) {
                Storage.RemoveToken();  // auto logout if 401 response returned from api
            }
    
            const error = data || response.statusText;
            return Promise.reject(error);
        }
    
        return data;
    }
}