/***********************************************
* Login.js
* 
* Handles the login of the application
***********************************************/
/* Layout files */
import '../assets/style/Login.css';

/* Global imports */
import React   from 'react';
import Popup   from 'reactjs-popup';
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

/* Local imports */
import API     from './API';
import Storage from './Storage';
import { HelpPopup } from './Popup';
import Cookie  from './Cookie';

import createGif  from "../assets/images/Create Account noloop.gif";
  
/* Allow this component to navigate to different pages */
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (<Component {...props} router={{ location, navigate, params }} />);
    }
  
    return ComponentWithRouterProp;
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        /* Check if user is logged in */
        var user = Storage.GetToken();
        if(user == null) { user = {username: ""}; }

        this.state = {
            username: "",
            loggedInUser: user["username"],
            password: "",
            fail: false,
            checkLogin: false,
            gotoUser: false,
            gotoChanged: false,
            gifSrc: "",
        };

        this.loginSuccess = this.loginSuccess.bind(this);
        this.loginFail    = this.loginFail.bind(this);
        this.handleEnter  = this.handleEnter.bind(this);
        this.gotoUser     = this.gotoUser.bind(this);
        this.gotoCreate   = this.gotoCreate.bind(this);
    }

    /* Check every second if the user is logged in */
    componentDidMount() {
        this.interval = setInterval(() => {
            var token = Storage.GetToken();
            if(token == null){ this.setState({ checkLogin: true }); }
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    /* Request a token for a user with password */
    login()       { API.GetToken(this.state.username, this.state.password, this.loginSuccess, this.loginFail); }
    /* On success, set the state of the user */
    loginSuccess(reply){ this.setState({fail: false, username: "", password: "", loggedInUser: this.state.username}); }
    /* On fail, don't login */
    loginFail()   { this.setState({fail: true}); }

    /* On enter in the input, try logging in */
    handleEnter(event){ if(event.key === "Enter"){ this.login(); }}

    /* If clicked on the user info button, goto page /user */
    gotoUser() {
        if(this.state.gotoChanged) {
            this.setState({gotoChanged: false});
            if(this.state.gotoUser) {
                return (<Navigate to={"/user"}/>);
            } else {
                return (<Navigate to={"/projects"}/>);
            }
        } else {
            return (<></>);
        }
    }

    /* If clicked on "Create Account", goto /createuser */
    gotoCreate() { 
        this.props.router.navigate("/createuser");
    }

    render() {
        const location = this.props.router.location;
        const navigate = this.props.router.navigate;
        var token = null;
        if(this.props.nologin !== true){
            token = Storage.GetToken();
            if(token != null) { token = token["userToken"]; }
        } else {
            token = "";
        }
        /* If logged in, show the photomation page */
        if(token != null) {
            return (
                <Cookie>
                <div className="fullPage">
                    <div className='header'>
                        {/* If not on the home page, show a button to go back to the home page */}
                        {(location.pathname !== "/projects") ?
                            <div className="homeDiv">
                                <div onClick={() => navigate("/projects")} className="button bigButton darkButton">Go Home</div>
                            </div>
                            : <></>
                        }
                        {(this.props.nologin === true) ? <></> :
                        <div className="logoutDiv">
                            <label>Welcome {this.state.loggedInUser}</label>
                            <Popup
                                trigger={<button className="bigButton darkButton iconButton"><span className="material-symbols-outlined">person</span></button>}
                                position="bottom right"
                                on="click"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                                contentStyle={{}}
                                arrow={false}
                                className="userPopup"
                            >
                                <div>
                                    <div onClick={() => this.setState(prevState => ({gotoUser: !prevState.gotoUser, gotoChanged: true}))} className="button bigButton darkButton">Edit Profile</div>
                                    <div onClick={() => Storage.RemoveToken()} className="button bigButton darkButton">Logout</div>
                                </div>
                            </Popup>
                            {this.gotoUser()}
                        </div>
                        }
                    </div>
                    {/* Show all the children of the <Login /> element */}
                    {this.props.children}
                </div>
                </Cookie>
            );
        } else {  /* If not logged in, show the login page */
            return (
                <Cookie>
                <div className="loginPage">
                    <div className="loginDiv">
                        <HelpPopup position="right" gif={createGif} />
                        <h1 className='center photomation'>Photomation</h1>
                        <p className='center powered'>powered by <a href="http://mapix.be" target="_blank" rel="noopener noreferrer"><span className="mapixLogo">mapix</span></a></p>
                        <div className="loginFail" style={(this.state.fail) ? {display: "block"} : {display: "none"}}>Login Failed:<br/> Wrong Credentials</div><br/>
                        <input type="text"     onChange={(e) => this.setState({username: e.target.value})} placeholder='Username' className="loginInput"></input><br/>
                        <input type="password" onChange={(e) => this.setState({password: e.target.value})} placeholder='Password' className="loginInput" onKeyDown={this.handleEnter}></input><br/>
                        <button onClick={() => this.gotoCreate()} className="bigButton createButton">Create Account</button>
                        <button onClick={() => this.login()} className="bigButton loginButton">Login</button>
                    </div>
                </div>
                </Cookie>
            );
        }
    }
}

export default withRouter(Login);