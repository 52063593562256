/* Layout files */
import './assets/style/Constants.css';
import './assets/style/App.css';

/* Global imports */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect }   from 'react';

/* Local imports */
import Cookie                   from './components/Cookie'
import Download                 from './components/Download';
import ExamplePage              from './components/ExamplePage';
import LandingPage              from './components/LandingPage';
import Login                    from './components/Login';
import Overview                 from './components/Overview';
import PhotomationCore          from './components/PhotomationCore';
import UserCreate, { UserInfo } from './components/UserInfo';
import RenderMarkdown           from './components/RenderMarkdown';

import privacyMarkdown from './docs/Privacy.md';
import tosMarkdown     from './docs/TermsOfService.md';

function App() {
    const tabletWidth = 1650;
    const phoneWidth = 500;
    const [isTablet, setIsTablet]       = useState((window.innerWidth <= tabletWidth) && (window.innerWidth > phoneWidth));
    const [isPhone, setIsPhone]         = useState((window.innerWidth <= phoneWidth));
    const reload = () => window.location.reload();

    const handleWindowSizeChange = () => {
        setIsPhone((window.innerWidth <= phoneWidth));
        setIsTablet((window.innerWidth <= tabletWidth) && (window.innerWidth > phoneWidth));
    };

    useEffect(() => {
        var _paq = window._paq = window._paq || [];
        _paq.push(['requireConsent']);
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
            var u="https://analytics.photomation.be/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var g=document.createElement('script');
            var s=document.getElementsByTagName('script')[0];
            g.async=true; 
            g.src=u+'matomo.js'; 
            s.parentNode.insertBefore(g,s);
        })();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route path="landing"        element={<Cookie><LandingPage/></Cookie>} />
                    <Route index path=""         element={<Cookie><LandingPage/></Cookie>} />
                    <Route path="example"        element={<Cookie><ExamplePage/></Cookie>} />
                    <Route path="privacy"        element={<Cookie><RenderMarkdown markdownFile={privacyMarkdown}/></Cookie> }/>
                    <Route path="termsofservice" element={<Cookie><RenderMarkdown markdownFile={tosMarkdown}/></Cookie> }/>
                    <Route path="projects"       element={<Login><PhotomationCore tablet={isTablet} phone={isPhone}/></Login>} />
                    <Route path="user"           element={<Login><UserInfo/></Login>} />
                    <Route path="createuser"     element={<Login nologin={true}><UserCreate/></Login>} />
                    <Route path="download"       element={<Login><Download /></Login>} />
                    <Route path="overview"       element={<Login><Overview /></Login>} />
                    <Route path="/Markers.pdf"   onEnter={reload} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
